import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {User} from '../app/utilities/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseURL = environment.baseApiUrl;
  public httpClient: HttpClient;
  constructor(_httpClient: HttpClient) {
    this.httpClient = _httpClient;
   }

   getAllUsers()
  {
    return this.httpClient.get<any>(this.baseURL+'User/GetAllUsers');
  }

  getUserInfo(userId:string)
  {
    return this.httpClient.get<User>(this.baseURL+'User/GetUserInfo?userId='+userId);
  }

  createOrUpdateUser(userData: any) {
    return this.httpClient.post<User>(this.baseURL + 'User/CreateOrUpdateUser', userData)
  }
}
