import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ProjectDescriptionService } from '../../../services/project-description.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjectReviewCommentComponent } from './review-comment/review-comment.component'
import { MenuItem } from 'primeng/api';
import { ProjectMilestoneComponent } from '../project-details/project-milestone/project-milestone.component';
import { MessageServiceComponent } from 'src/services/message.service';
import { ProjectDetailsService } from 'src/services/project-details.service';

interface projectDetailsRisk {
    projectDetailsRiskId: number,
    projectId: number;
    risk: string;
    probability: string;
    severity: string;
    mitigation: string;
    modifiedBy: number,
    createdBy: number
}
@Component({
    selector: 'app-project-description',
    templateUrl: './project-description.component.html',
    styleUrls: ['./project-description.component.scss'],
    providers: [DialogService]
})
export class ProjectDescriptionComponent implements OnInit {
    private projectId: number;
    private projectDetailsId: number;
    projectDetailsRiskId: number;
    projectDetails = [];
    isEdit: boolean = true;
    projectdetailrisk: projectDetailsRisk[];
    projectStatus: any;
    details = [{ risk: '' }, { probability: '' }, { severity: '' }, { mitigation: '' }];
    todaysMilestonedate: Date | undefined;
    isProjectDetailsHistoryVisible = false;
    projects=[];
    colorOptions = [];
    currentProject = {};
    currentUser: any;
    startDate: any;
    endDate: any;

    milestoneItems: MenuItem[] = []
    activeIndex: number = 0;

    
    ref: DynamicDialogRef | undefined;

   
    messageService: any;

    statusOptions = [
        { label: 'Open', code: 1 },
        { label: 'In Progress', code: 2 },
        { label: 'Completed', code: 3 },
    ]
    selectedStatus: any;

    constructor(private _projectDetailsService: ProjectDetailsService, private _projectService: ProjectDescriptionService, private breadcrumbService: AppBreadcrumbService, private route: ActivatedRoute, public dialogService: DialogService, private fb:FormBuilder, private messageServiceComponent: MessageServiceComponent) {
        this.breadcrumbService.setItems([
            { label: 'Back to Projects', routerLink: (this.projectId > 0) ? ['/projectdescription/' + this.projectId] : ['/projects'] }
        ]);

     }
    
    ngOnInit(): void {
        this.projectId = Number(this.route.snapshot.paramMap.get('projectId'));
        
        this.messageServiceComponent.getUser().subscribe((res) => {
            this.currentUser = res;
            
        })
        
        this.getProjectDescription();
        this.getProjectDetails();
        this.getAllProject();
        this.getProjectStatus();
        this.getMilestone()

        const { previousMonday, previousFriday } = this.getPreviousWeekDates();

        // this.startDate = window.localStorage.getItem("startDate");
        // this.endDate = window.localStorage.getItem("endDate");
    }
    

    async getProjectDescription() {
        await this._projectService.getProjectDetailsRisk(this.projectId).subscribe((res) => {
            this.projects = res;
            
        }),
        (err) => {
            };
    }

    async getProjectDetails() {
        
        await this._projectService.getProjectDetails(this.projectId).subscribe((res) => {
            this.projectDetails = res;
            if (this.projectDetails) {
                this.projectDetailsId = this.projectDetails[0].projectDetailsId
            }
            
        }),
        (err) => {
        };
    }


    async getProjectStatus() {
        this.projectStatus = await this._projectService.getAllProjectStatus();
    }


    async submitForm(projectDetailsForm: any) {
        if (projectDetailsForm != undefined && projectDetailsForm != null) {
            var project = {
                "projectDetailsId": 0,
                "projectId": this.projectId,
                "projectDesc": projectDetailsForm.projectDesc,
                "scopeStatus": projectDetailsForm.scopeStatus,
                "scopeDesc": projectDetailsForm.scopeDesc,
                "scheduleStatus": projectDetailsForm.scheduleStatus,
                "scheduleDesc": projectDetailsForm.scheduleDesc,
                "qualityStatus": projectDetailsForm.qualityStatus,
                "qualityDesc": projectDetailsForm.qyalityDesc,
                "budgetStatus": projectDetailsForm.budgetStatus,
                "budgetDesc": projectDetailsForm.budgetDesc,
                "resourceStatus": projectDetailsForm.resourceStatus,
                "resourceDesc": projectDetailsForm.resourceDesc,
                "modifiedBy": this.currentUser.userId,
                "createdBy": this.currentUser.userId
            };
            

            const severityMapping: { [key: string]: string } = {
                '1': 'Low',
                '2': 'Medium', 
                '3': 'High',
            };

            const probabilityMapping: { [key: string]: string } = {
                '1': 'Low',
                '2': 'Medium', 
                '3': 'High',
            };
            const updatedData = this.projects.map(item => {
                if (item.severity && severityMapping[item.severity]) {
                    item.severity = severityMapping[item.severity];
                }

                if (item.probability && probabilityMapping[item.probability]) {
                    item.probability = probabilityMapping[item.probability];
                }

                return item;
            });

            updatedData.forEach((projectDetailObj) => {
                this._projectService.createOrUpdateProjectDetailsRisks(projectDetailObj).subscribe((res) => {
                    this.projects = res;
                })
            })
            await this._projectService.addProjectDetails(project).subscribe((res) => {
                this.projectDetails = [];
            });
            
        }
    }

    async getAllProject() {
        await this._projectService.getProjectById(this.projectId).subscribe((res) => {
            this.currentProject = res;
            
        })
    }

    show() {
        this.isProjectDetailsHistoryVisible = !this.isProjectDetailsHistoryVisible;
        // this.ref = this.dialogService.open(ProjectDescriptionHistoryComponent, {
        //     data:{
        //         projectId: this.projectId,
        //     },
        //     // header: 'Project Description History',
        //     width: '70%',
        //     contentStyle: { overflow: 'hidden' },
        //     baseZIndex: 10000
        // });
    }

    showReviewComment() {
        this.ref = this.dialogService.open(ProjectReviewCommentComponent, {
            data:{
                projectId: this.projectId,
            },
            header: 'Project Review Comments',
            width: '70%',
            contentStyle: { overflow: 'hidden' },
            baseZIndex: 10000
        });
    }

    addRow() {
        const newDetails: projectDetailsRisk = {
            projectDetailsRiskId: 0,
            projectId: this.projectId,
            risk: "",
            severity: "",
            probability: "",
            mitigation: "",
            createdBy: this.currentUser.userId, 
            modifiedBy: this.currentUser.userId
        };
        this.projects.push(newDetails);
    }


    removeRow() {
        if (this.projects.length > 1) {
            this.projects.pop();
        } else {
        }
    }

    getPreviousWeekDates() {
        const today = new Date();
        const currentDayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

        const daysToMonday = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
        const previousMonday = new Date(today);
        previousMonday.setDate(today.getDate() - daysToMonday - 7); 

        const daysToFriday = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 5;
        const previousFriday = new Date(today);
        previousFriday.setDate(today.getDate() - daysToFriday - 7); 

        const formattedPreviousMonday = this.formatDate(previousMonday);
        const formattedPreviousFriday = this.formatDate(previousFriday);

        return {
            previousMonday: formattedPreviousMonday,
            previousFriday: formattedPreviousFriday,
        };
    }

    formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    onRowEditSave(product: any) {
        var milestone = {
            milestoneId: product.milestoneId,
            projectId: this.projectId,
            milestoneName: product.milestoneName,
            milestoneDesc: product.milestoneDesc,
            milestoneDate: product.milestoneDate,
            milestoneStatus: product.milestoneStatus.code
        }
        this._projectDetailsService.createMilestones(milestone).subscribe((res) => {
            this.getMilestone();
        })
    }

    // Milestone functions

    onActiveIndexChange(event: number) {
        this.activeIndex = event;
        this.messageServiceComponent.updateProjectId(this.projectId);
        this.messageServiceComponent.updateMilestoneEventId(event);
        
    }

    getMilestone() {
        this._projectDetailsService.getMilestones(this.projectId).subscribe((res: any[]) => {
            // this.milestoneItems = res.map(item => {
            //     const { milestoneName, ...rest } = item;
            //     return { label: milestoneName, ...rest };
            // });
            this.milestoneItems = res;
        })
    }

    addMilestone() {
        this.ref = this.dialogService.open(ProjectMilestoneComponent, {
            data:{
                projectId: this.projectId,
            },
            header: 'Add Project Milestone',
            width: '40%',
            contentStyle: { overflow: 'auto' },
            baseZIndex: 10000
        });
    }

    isMilestoneDateExpired(project: any): boolean {
        const milestoneDate = new Date(project.milestoneDate);
        const currentDate = new Date();
        return milestoneDate < currentDate && project.milestoneStatus != 3;
    }

 }