import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../../services/project.service';
import { UserService } from '../../../services/user.service'
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { MessageServiceComponent } from 'src/services/message.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjectReviewCommentComponent } from '../project-description/review-comment/review-comment.component';
import { Table } from 'primeng/table';
import { User } from 'src/app/utilities/user';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  providers: [DialogService]
})
export class ProjectsComponent implements OnInit {
  projects: [] = [];
  projectId = 0;
  currentUser: any
  isAdmin: boolean;
  users: SelectItem[];
  projectStatus: any;
  isVisibleAddProjectDialog = false;
  isVisibleAddProjectDetailsDialog = false;
  clonedProducts: { [s: string]: any; } = {};
  ref: DynamicDialogRef | undefined;
  projectForm: FormGroup = new FormGroup({
    projectId: new FormControl(),
    projectName: new FormControl(''),
    managerId: new FormControl(),
    manager: new FormControl(),
    leadById: new FormControl(),
    leadBy: new FormControl(),
    customerName: new FormControl('')
  });

  // get project data based on week ranges
  currentDate = new Date();
  startOfWeek: any;
  weekCount: number = -1;
  isPrevClicked: boolean = false;
  startDate: any;
  endDate: any;
  userInfo:any;

  selectedProjects: any;
  constructor(private _projectService: ProjectService, private breadcrumbService: AppBreadcrumbService, private _userService: UserService, private messageService: MessageService, private messageServiceComponent: MessageServiceComponent, public dialogService: DialogService,private msalService: MsalService) {
    this.breadcrumbService.setItems([
      { label: 'Projects', routerLink: ['/projects'] }
    ]);
  }

  async ngOnInit() {
   await this.fetchUserInfo();

    this.projects = [];
    await this.getAllUsers();
    // await this.getAllProjects();
    await this.getProjectStatus();
    this.messageServiceComponent.getUser().subscribe((res) => {
      this.currentUser = res;
      this.currentUser.isAdmin == null || this.currentUser.isAdmin == false ? this.isAdmin = false : this.isAdmin = true;
    });

    let today = new Date();
    today = this.currentDate;
    
    const { previousWeekStartDate, previousWeekEndDate } = this.getPreviousWeekStartAndEndDates(today);
    
    this.startDate = previousWeekStartDate;
    this.endDate = previousWeekEndDate;
  }

  toggleAddProjectDialog() {
    this.isVisibleAddProjectDialog = !this.isVisibleAddProjectDialog;
  }

  async getAllProjects() {
    // await this._projectService.getAllProjects().subscribe((res) => {
    //   this.projects = res;
    // },
    //   (err) => {

    //   });
  }

  async getAllUsers() {
    this.users = [];
    await this._userService.getAllUsers().subscribe((res) => {
      (res as Array<any>).forEach((user) => {
        this.users.push({ label: user.name, value: user.userID });
      });
      
    },
      (err) => {

      });
  }

  async getProjectStatus() {
    this.projectStatus = await this._projectService.getAllProjectStatus();
  }
  onRowEditInit(product: any) {
    this.clonedProducts[product.projectId] = { ...product };
  }
  async onRowEditSave(project: any) {
    delete this.clonedProducts[project.projectId];
    await this.addOrUpdateProject(project);
  }

  onRowEditCancel(product: any, index: number) {
  }

  async addOrUpdateProject(projectData: any) {
    var project;
    
    if (projectData != undefined && projectData != null) {
      project = {
        "projectID": projectData.projectId == null ? 0 : projectData.projectId,
        "projectName": projectData.projectName,
        "manager": this.projectForm.value.manager == null ? projectData.managerId : this.projectForm.value.manager,
        "leadBy": this.projectForm.value.leadBy == null ? projectData.leadById : this.projectForm.value.leadBy,
        "customerName": projectData.customerName,
        "createdBy": this.currentUser.userId,
        "modifiedBy": this.currentUser.userId
      };

      if (
        project.projectName == null ||
        project.projectName == '' ||
        project.manager == null ||
        project.leadBy == null ||
        project.customerName == '' ||
        project.customerName == null) {
        this.messageService.add({key: 'bc', severity:'error', summary: 'Invalid Input', detail: 'Fields are empty'});
        
      }
      else {
        
        await this._projectService.addUpdateProject(project).subscribe(async (res) => {
        this.messageService.add({key: 'bc', severity:'success', summary: 'Success', detail: res.message});
          
          await this.getAllProjects();
          this._projectService.getAllProjects(this.startDate.toLocaleDateString(), this.endDate.toLocaleDateString()).subscribe((res) => {
            this.projects = res;
          })
          
          // await this.ngOnInit();
          this.isVisibleAddProjectDialog = false;
          this.projectForm.reset()
          
        },
          (err) => {
          });
      }
    }
  }

  async addProjectDetails(projectDetailsForm: any) {
    if (projectDetailsForm != undefined && projectDetailsForm != null) {
      var project = {
        "projectDetailsId": 0,
        "projectId": projectDetailsForm.projectId,
        "projectDesc": projectDetailsForm.projectDesc,
        "scopeStatus": projectDetailsForm.scopeStatus,
        "scopeDesc": projectDetailsForm.scopeDesc,
        "scheduleStatus": projectDetailsForm.scheduleStatus,
        "scheduleDesc": projectDetailsForm.scheduleDesc,
        "qualityStatus": projectDetailsForm.qualityStatus,
        "qualityDesc": projectDetailsForm.qyalityDesc,
        "budgetStatus": projectDetailsForm.budgetStatus,
        "budgetDesc": projectDetailsForm.budgetDesc,
        "resourceStatus": projectDetailsForm.resourceStatus,
        "resourceDesc": projectDetailsForm.resourceDesc
      };

      await this._projectService.addProjectDetails(project).subscribe((res) => {
        this.messageService.add({key: 'bc', severity:'success', summary: 'Success', detail: res.message});
      },
        (err) => {
        });
      this.getAllProjects();
    }
  }

  async addReviewComments(project: any){
    this.messageServiceComponent.updateProjectId(project.projectId);
    this.ref = this.dialogService.open(ProjectReviewCommentComponent, {
            data:{
                projectId: this.projectId,
            },
            header: 'Project Review Comments',
            width: '70%',
            contentStyle: { overflow: 'hidden' },
            baseZIndex: 10000
        });
  }

  getPreviousWeekStartAndEndDates(currentDate: Date): { previousWeekStartDate: Date, previousWeekEndDate: Date } {
    let dayOfWeek = currentDate.getDay();
    
    const diff = currentDate.getDate() - dayOfWeek - 6;
    
    const previousWeekStartDate = new Date(currentDate);
    previousWeekStartDate.setDate(diff);
    previousWeekStartDate.setHours(0, 0, 0, 0);

    const previousWeekEndDate = new Date(previousWeekStartDate);
    previousWeekEndDate.setDate(previousWeekStartDate.getDate() + 6);
    previousWeekEndDate.setHours(23, 59, 59, 999);

    this.startOfWeek = previousWeekStartDate;
    this.weekCount += 1;      

    this._projectService.getAllProjects(this.formatDate(previousWeekStartDate), this.formatDate(previousWeekEndDate)).subscribe((res) => {
        this.projects = res;

        this.startDate = previousWeekStartDate;
        this.endDate = previousWeekEndDate;
    });

    return { previousWeekStartDate, previousWeekEndDate };
    
}

  getNextWeekStartAndEndDates(currentDate: Date): { nextWeekStartDate: Date, nextWeekEndDate: Date } {
    let dayOfWeek = currentDate.getDay() - 1;
    if (dayOfWeek === -1) {
        dayOfWeek = 6;
    }

    const diff = currentDate.getDate() - dayOfWeek + 7;
    const nextWeekStartDate = new Date(currentDate);
    nextWeekStartDate.setDate(diff);
    nextWeekStartDate.setHours(0, 0, 0, 0);

    const nextWeekEndDate = new Date(nextWeekStartDate);
    nextWeekEndDate.setDate(nextWeekStartDate.getDate() + 6);
    nextWeekEndDate.setHours(23, 59, 59, 999);

    this.startOfWeek = nextWeekStartDate;
    this.weekCount -= 1;
    
    
    this._projectService.getAllProjects(this.formatDate(nextWeekStartDate), this.formatDate(nextWeekEndDate)).subscribe((res) => {
      this.projects = res;
      
      this.startDate = nextWeekStartDate;
      this.endDate = nextWeekEndDate;
    })

    return { nextWeekStartDate, nextWeekEndDate };

  }

  formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    return formattedMonth + '-' + formattedDay   + '-' + year;
  } 

  GetPreviousProjects() {
    const { previousWeekStartDate, previousWeekEndDate } = this.getPreviousWeekStartAndEndDates(this.startOfWeek);
    
  }

  GetNextProjects() {
    const { nextWeekStartDate, nextWeekEndDate } = this.getNextWeekStartAndEndDates(this.startOfWeek);
  }

  async updateWeekDates() {
    this.messageServiceComponent.updateWeekStartEndDate({startDate: this.startDate, endDate: this.endDate})
    
  }

  clear(table: Table) {
    table.clear();
  }

  async fetchUserInfo() {
    const emailId = this.msalService.instance.getAllAccounts()[0]?.username;
    if (emailId) {
      try {
        const res = await this._userService.getUserInfo(emailId).toPromise();
        this.userInfo = res;
      } catch (error) {
        console.error('Error retrieving user info:', error);
      }
    } else {
      console.error('Email ID not found');
    }
  }
 

   isActionAllowed(): boolean {
    if(!this.userInfo){return false;}
    const designation = this.userInfo.designation.trim().toLowerCase();
    return this.userInfo ? (this.userInfo.isAdmin || designation==='sr. vice president') : false;
  }

  async toggleStatus(projectId: number) {
    if (projectId != null) { 
      try {
        const updatedProject = await this._projectService.updateProjectStatus(projectId).toPromise();
        this.messageService.add({ key: 'bc', severity: 'success', summary: 'Success', detail: updatedProject.message });

        await this.getAllProjects();
        const formattedStartDate = this.formatDate(this.startDate);
        const formattedEndDate = this.formatDate(this.endDate);

        this._projectService.getAllProjects(formattedStartDate, formattedEndDate).subscribe((res) => {
          this.projects = res;
        });
       
      } catch (error) {
        console.error('Error toggling project status', error);
      }
    } else {
      console.error('Project or project id is not defined');
    }
  }


}
