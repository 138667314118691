import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { DisplayComponent } from './utilities/display.component';
import { ElevationComponent } from './utilities/elevation.component';
import { FlexboxComponent } from './utilities/flexbox.component';
import { GridComponent } from './utilities/grid.component';
import { WidgetsComponent } from './utilities/widgets.component';
import { SpacingComponent } from './utilities/spacing.component';
import { TypographyComponent } from './utilities/typography.component';
import { TextComponent } from './utilities/text.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppHelpComponent } from './pages/app.help.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { ProjectDetailsRiskComponent } from './pages/project-details-risk/project-details-risk.component';
import { AuthGuardGuard } from './utilities/auth-guard.guard';
import { MsalGuard } from '@azure/msal-angular';
import { ProjectDescriptionComponent } from './pages/project-description/project-description.component';
import { UsersComponent } from './pages/users/users.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    { path: '', component: ProjectsComponent, pathMatch: "full" },
                    { path: 'users', component: UsersComponent, pathMatch: "full" },
                    { path: 'utilities/display', component: DisplayComponent },
                    { path: 'utilities/elevation', component: ElevationComponent },
                    { path: 'utilities/flexbox', component: FlexboxComponent },
                    { path: 'utilities/grid', component: GridComponent },
                    { path: 'utilities/widgets', component: WidgetsComponent },
                    { path: 'utilities/spacing', component: SpacingComponent },
                    { path: 'utilities/typography', component: TypographyComponent },
                    { path: 'utilities/text', component: TextComponent },
                    { path: 'pages/invoice', component: AppInvoiceComponent },
                    { path: 'pages/help', component: AppHelpComponent },
                    { path: 'projects', component: ProjectsComponent },
                    { path: 'projectdetails/:projectId', component: ProjectDetailsComponent },
                    { path: 'projectdetailsrisk', component: ProjectDetailsRiskComponent },
                    { path: 'projectdetailsrisk/:projectId', component: ProjectDetailsRiskComponent },
                    { path: 'projectdescription/:projectId', component : ProjectDescriptionComponent }
                ],
                canActivate: [MsalGuard]
            },
            { path: 'error', component: AppErrorComponent },
            { path: 'access', component: AppAccessdeniedComponent },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: 'login', component: AppLoginComponent, canActivate: [AuthGuardGuard] },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
