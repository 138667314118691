import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent {
  constructor(private msalService: MsalService) { 
    
  }

  async ngOnInit()  {  
    await this.login();  
  }
  async login() {
      await this.msalService.loginRedirect();
  }
}
