import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SelectItem } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class ProjectDescriptionService {

    baseURL = environment.baseApiUrl;
    public httpClient: HttpClient;
    constructor(_httpClient: HttpClient) {
        this.httpClient = _httpClient;
    }

    getProjectDetailsRisk(projectId: number = 0) {
        return this.httpClient.get<any>(this.baseURL + 'Project/GetProjectDetailsRisk?projectId=' + projectId);
    }

    getProjectDetails(projectId: number = 0) {
        return this.httpClient.get<any>(this.baseURL + 'Project/GetProjectDetails?projectId=' + projectId);
    }

    getAllProjects() {
        return this.httpClient.get<any>(this.baseURL + 'Project/GetAllProjects');
    }
    
    getProjectById(projectId: number) {
        return this.httpClient.get<any>(this.baseURL + 'Project/GetProject?projectId=' + projectId);
    }

    getProjectRiskHistory(startDate: Date, endDate: Date, projectId: number) {
        return this.httpClient.get<any>(this.baseURL + "Project/ProjectDetailsRiskHistory?startDate=" + startDate.toDateString() + "&endDate=" + endDate.toDateString() + "&projectId=" + projectId);
    }

    getProjectDetailHistory(startDate: Date, endDate: Date, projectId: number) {
        return this.httpClient.get<any>(this.baseURL + "Project/ProjectDetailsHistory?startDate=" + startDate.toDateString() + "&endDate=" + endDate.toDateString() + "&projectId=" + projectId);
    }

    getAllComments(projectId: number) {
        return this.httpClient.get<any>(this.baseURL + 'Project/GetReviewComment?projectId=' + projectId);
    }

    createComment(comment: any) {
        return this.httpClient.post<any>(this.baseURL + 'Project/CreateOrUpdateReviewComment', comment)
    }
    addProjectDetails(projectDetails: any) {
        return this.httpClient.post<any>(this.baseURL + 'Project/CreateOrUpdateProjectDetails', projectDetails);
    }

    getAllProjectStatus() {
        var status: SelectItem[] = [
            { label: 'Low', value: '1' },
            { label: 'Medium', value: '2' },
            { label: 'High', value: '3' },
        ];
        return status;
    }

    createOrUpdateProjectDetailsRisks(projectDetailsRisk: any) {
        return this.httpClient.post<any>(this.baseURL + 'Project/CreateOrUpdateProjectDetailsRisks', projectDetailsRisk)
    }
}