/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */


import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from '../environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const msalConfig: Configuration = {
    auth: {
        clientId: environment.sso.adClientId,
        authority: 'https://login.microsoftonline.com/'+environment.sso.adTenantId,
        redirectUri: environment.redirectUriBasePath+'/projects',
        postLogoutRedirectUri:'/login',
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
            },
            logLevel: LogLevel.Verbose
        }
    }
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = new Map(
[
    ["https://localhost:7278",["api://1b087f04-bed5-42f7-98c4-9ac608f9bb25/access-user"]],
    ["https://pocvm.centralindia.cloudapp.azure.com",["api://1b087f04-bed5-42f7-98c4-9ac608f9bb25/access-user"]],
    ["https://localhost",["api://1b087f04-bed5-42f7-98c4-9ac608f9bb25/access-user"]],
    ["http://localhost",["api://1b087f04-bed5-42f7-98c4-9ac608f9bb25/access-user"]],
    ["http://governance.cozentus.com",["api://1b087f04-bed5-42f7-98c4-9ac608f9bb25/access-user"]],
    ["https://governance.cozentus.com",["api://1b087f04-bed5-42f7-98c4-9ac608f9bb25/access-user"]],
    ["https://governanceapi.cozentus.com",["api://1b087f04-bed5-42f7-98c4-9ac608f9bb25/access-user"]]
]);

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: environment.msalLogInRequest
};