import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MessageServiceComponent {

    private user = new BehaviorSubject([]);
    private milestoneEventId = new BehaviorSubject(0);
    private projectId = new BehaviorSubject(0);
    private startEndDate = new BehaviorSubject({})
    private loading: boolean = false;

    getUser() {
        return this.user.asObservable();
    }

    updateUser(newValue: any) {
        this.user.next(newValue);
    }

    getMilestoneEventId() { 
        return this.milestoneEventId.asObservable();
    }
    
    updateMilestoneEventId(newValue: number) {
        
        this.milestoneEventId.next(newValue)
    }
    // Get Project Id
    getProjectId() { 
        return this.projectId.asObservable();
    }
    // Initialize Project Id
    updateProjectId(newValue: any) {
        this.projectId.next(newValue);
    }

    getWeekStartEndDate() {
        return this.startEndDate.asObservable();
    }

    updateWeekStartEndDate(newValue: any) {
        this.startEndDate.next(newValue);
        
        window.localStorage.setItem("startDate", this.startEndDate.value['startDate'])
        window.localStorage.setItem("endDate", this.startEndDate.value['endDate'])
    }

    getLoading(): boolean{
        return this.loading;
    }

    updateLoading(loading: boolean) {
        this.loading = loading;
    }

    constructor() {}
 }