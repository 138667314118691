import {Component, OnInit} from '@angular/core';
import {AppComponent} from './app.component';
import { MessageServiceComponent } from 'src/services/message.service';

@Component({
    selector: 'app-menu',
    template: `
        <ul class="layout-menu">
            <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
        </ul>
    `
})
export class AppMenuComponent implements OnInit {

    model: any[];
    user: any;

    constructor(public app: AppComponent, private messageServiceComponent: MessageServiceComponent) {}

    ngOnInit() {
        this.messageServiceComponent.getUser().subscribe((res) => {
            this.user = res;

            if (this.user.isAdmin) {
                this.model = [
                    {
                        label: 'Projects', icon: 'pi pi-list', routerLink: ['/projects']
                    },
                    {
                        label: 'Users', icon: 'pi pi-users', routerLink: ['/users']
                    }
                ];
            }
            else {
                this.model = [
                    {
                        label: 'Projects', icon: 'pi pi-list', routerLink: ['/projects']    
                        }
                    ];
                }
            })
    }
}
