import { Component, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { MsalService } from '@azure/msal-angular';
import { UserService } from 'src/services/user.service';
import { User } from './utilities/user';
import { Settings } from './utilities/settings';

@Component({
    selector: 'app-topbar',
    template: `
        <div class="layout-topbar">
            <div class="layout-topbar-wrapper">
                <div class="layout-topbar-left">
                    <div class="layout-topbar-logo" id="logolink" style="cursor: pointer; outline: none;" routerLink="/">
                        <img id="app-logo"
                             [src]="'assets/images/logo_cozentus.png'"
                             alt="poseidon-layout">
                    </div>
                </div>

                <div class="layout-topbar-right">
                    <a class="menu-button" href="#" (click)="appMain.onMenuButtonClick($event)">
                        <i class="pi pi-bars"></i>
                    </a>

                    <ul class="layout-topbar-actions">                        
                        <li #profile class="topbar-item user-profile"
                            [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === profile}">
                            <a href="#" (click)="appMain.onTopbarItemClick($event,profile)">
                                <img class="profile-image" src="assets/layout/images/avatar-no-profile.png" alt="demo">
                                <div class="profile-info">
                                    <h6>{{user.userName}}</h6>
                                    <span>{{user.designation}}</span>
                                </div>
                            </a>

                            <ul class="fadeInDown">
                                <li class="layout-submenu-header">
                                    <img class="profile-image" src="assets/layout/images/avatar-no-profile.png" alt="demo">
                                    <div class="profile-info">
                                    <h6>{{user.userName}}</h6>
                                    <span>{{user.designation}}</span>
                                    </div>
                                </li>
                                <li role="menuitem">
                                    <a href="#" (click)="logOut()">
                                        <i class="pi pi-power-off"></i>
                                        <h6>Logout</h6>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    `
})
export class AppTopBarComponent implements OnInit {
    logoutConfirmation: boolean = false;
    user: User;
    constructor(public appMain: AppMainComponent, public app: AppComponent, private msalService: MsalService) {

    }
    ngOnInit() {
        Settings.User.subscribe((res) => { this.user = res });
    }



    async logOut() {
        this.logoutConfirmation = false
        await this.msalService.logoutRedirect();
    }



}
