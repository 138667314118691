import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SelectItem } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { UserService } from './user.service';
import { MessageServiceComponent } from './message.service';
import { Settings } from 'src/app/utilities/settings';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  baseURL = environment.baseApiUrl;
  public httpClient: HttpClient;
  userInfo:any;

  constructor(_httpClient: HttpClient,private msalService: MsalService, private userService: UserService, private messageServiceComponent: MessageServiceComponent) {
    this.httpClient = _httpClient;
    this.getUserInfo();
  }

  getAllActiveInActiveProjects(startDate: string, endDate: string) {
    return this.httpClient.get<any>(this.baseURL + 'Project/GetAllProjects?startDate='+ startDate + '&endDate=' + endDate);
  }
  getAllActiveProjects(startDate: string, endDate: string) {
    return this.httpClient.get<any>(this.baseURL + 'Project/GetAllActiveProjects?startDate='+ startDate + '&endDate=' + endDate);
  }
  getUserInfo(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const emailId = this.msalService.instance.getAllAccounts()[0]?.username;
      if (emailId) {
        this.userService.getUserInfo(emailId).subscribe(
          res => {
            this.userInfo = res;
            resolve();
          },
          error => {
            console.error('Error retrieving user info:', error);
            reject(error);
          }
        );
      } else {
        console.error('Email ID not found');
        reject('Email ID not found');
      }
    });
  }

  getAllProjects(startDate: string, endDate: string): Observable<[]> {
    return new Observable<[]>(observer => {
      this.getUserInfo().then(() => {
        if (!this.userInfo) {
          console.log("user info " + ""+this.userInfo);
          observer.next([]);
          observer.complete();
          return;
        }
        const designation = this.userInfo.designation.trim().toLowerCase();
        const projects$ = this.userInfo.isAdmin || designation === 'sr. vice president' ?
          this.getAllActiveInActiveProjects(startDate, endDate) :
          this.getAllActiveProjects(startDate, endDate);
        projects$.subscribe(
          projects => {
            observer.next(projects);
            observer.complete();
          },
          error => observer.error(error)
        );
      }).catch(error => observer.error(error));
    });
  }


  getProjectDetails(projectId: number = 0) {
    return this.httpClient.get<any>(this.baseURL + 'Project/GetProjectDetails?projectId=' + projectId);
  }

  addUpdateProject(project:any)
  {
    return this.httpClient.post<any>(this.baseURL + 'Project/CreateOrUpdateProject', project);
  }

  addProjectDetails(projectDetails:any)
  {
    return this.httpClient.post<any>(this.baseURL + 'Project/CreateOrUpdateProjectDetails', projectDetails);
  }

  getAllProjectStatus() {
    var status: SelectItem[] = [
      { label: 'Low', value: '1' },
      { label: 'Medium', value: '2' },
      { label: 'High', value: '3' },
    ];
    return status;
  }

  createUpdateProjectDetails(projectDetailsId,projectID, projectDesc, scopeStatus, scopeDesc, scheduleStatus, scheduleDesc, qualityStatus,qualityDesc,budgetStatus,budgetDesc,resourceStatus,resourceDesc,modifiedBy,createdBy) {
    var body = {
      projectDetailsId: projectDetailsId,
      projectID: projectID,
      projectDesc: projectDesc,
      scopeStatus: scopeStatus,
      scopeDesc: scopeDesc,
      scheduleStatus: scheduleStatus,
      scheduleDesc: scheduleDesc,
      qualityStatus: qualityStatus,
      qualityDesc: qualityDesc,
      budgetStatus: budgetStatus,
      budgetDesc: budgetDesc,
      resourceStatus: resourceStatus,
      resourceDesc: resourceDesc,
      modifiedBy: modifiedBy,
      createdBy: createdBy
    }    
    return this.httpClient.post(this.baseURL + 'Project/CreateOrUpdateProjectDetails', body)
  }


updateProjectStatus(id: number) :Observable<any>{
  debugger;
  return this.httpClient.get<any>(this.baseURL + 'Project/Update?id=' + id)
}

}
