import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ProjectDetailsService } from '../../../services/project-details.service';
import { MessageService, MenuItem } from 'primeng/api';
import { MessageServiceComponent } from 'src/services/message.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjectMilestoneComponent } from './project-milestone/project-milestone.component';
import * as FileSaver from 'file-saver';

interface projectDetailsRisk {
    projectDetailsRiskId: number,
    projectId: number;
    risk: string;
    riskstatus: boolean;
    probability: string;
    severity: string;
    mitigation: string;
    modifiedBy: number,
    createdBy: number
}


@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
  providers: [DialogService]
})
export class ProjectDetailsComponent implements OnInit {
private projectId: number;
    private projectDetailsId: number;
    projectDetailsRiskId: number;
    projectDetails = [];
    currentUser: any;
    isEdit: boolean = true;
    isProjectDetailsEmpty: boolean = false;
    risk
    projectdetailrisk: projectDetailsRisk[];
    projectStatus: any;
    details = [{ risk: '' }, { probability: '' }, { severity: '' }, { mitigation: '' }];
    mondayDate: any;
    sundayDate: any;
    projectsLength: any;
 
    projects = [];
    colorOptions = [];
    currentProject = {};
    status = {};
    items: MenuItem[] | undefined
    milestoneItems: MenuItem[] = []
    activeIndex: number = 0;
    statusOptions = [
        { label: 'Open', code: 1 },
        { label: 'In Progress', code: 2 },
        { label: 'Completed', code: 3 },
    ]
    selectedStatus: any;

    // project description state variables
    managerStatus: ''
    projectDescription: ''
    scopeStatus: number;
    scopeDesc: ''
    scheduleStatus: number;
    scheduleDesc: ''
    qualityStatus: number;
    qualityDesc: ''
    budgetStatus: number;
    budgetDesc: ''
    resourceStatus: number;
    resourceDesc: ''
    ref: DynamicDialogRef;

    milestoneName: string;
    milestoneDesc: string;
    milestoneStatus: any;
    milestoneDate: string;

    projectDate: Date;

    constructor(private _projectService: ProjectDetailsService, private breadcrumbService: AppBreadcrumbService, private route: ActivatedRoute,  private fb:FormBuilder, private messageService: MessageService, private messageServiceComponent: MessageServiceComponent, public dialogService: DialogService) {
        this.breadcrumbService.setItems([
            { label: 'Back to Projects', routerLink: (this.projectId > 0) ? ['/projectdetails/' + this.projectId] : ['/projects'] }
        ]);
 
     }
   
    ngOnInit(): void {
        this.projectId = Number(this.route.snapshot.paramMap.get('projectId'));
       
        this.getProjectDescription();
        this.getProjectDetails();
        this.getAllProject();
        this.getProjectStatus();

        this.messageServiceComponent.getUser().subscribe((res) => {
            this.currentUser = res;
        })

        var monday = this.getMonday();
        var sunday = this.getSunday();
        this.mondayDate = this.formatDate(monday);
        this.sundayDate = this.formatDate(sunday);

        this.getMilestone()

    }
   
 
    async getProjectDescription() {
        await this._projectService.getProjectDetailsRisk(this.projectId).subscribe((res) => {
            this.projects = res;
            if (this.projects == null) {
                this.projects = []
                this.projectsLength = 0
            }
            else {
                this.projectsLength = this.projects.length;
            }
            
        }),
        (err) => {
        };
    }
 
    async getProjectDetails(){
        await this._projectService.getProjectDetails(this.projectId).subscribe((res) => {
            this.projectDetails = res;
            if (this.projectDetails == null) {
                this.projectDetails = []
            }
            else {
                this.projectDetailsId = this.projectDetails[0].projectDetailsId
                
                this.managerStatus = this.projectDetails[0].projectStatus;
                this.projectDescription = this.projectDetails[0].projectDesc
                this.scopeStatus = this.projectDetails[0].scopeStatus
                this.scopeDesc = this.projectDetails[0].scopeDesc
                this.scheduleStatus = this.projectDetails[0].scheduleStatus
                this.scheduleDesc = this.projectDetails[0].scheduleDesc
                this.qualityStatus = this.projectDetails[0].qualityStatus
                this.qualityDesc = this.projectDetails[0].qualityDesc
                this.budgetStatus = this.projectDetails[0].budgetStatus
                this.budgetDesc = this.projectDetails[0].budgetDesc
                this.resourceStatus = this.projectDetails[0].resourceStatus
                this.resourceDesc = this.projectDetails[0].resourceDesc
            }
            
           
        }),
        (err) => {
            if (err.status === 404) {
                this.projectDetails = [];
            } else {
                // Handle other errors as needed
            }
            };
        if (this.projectDetails.length == 0) {
            this.isProjectDetailsEmpty = true;
        }
    }
 
 
    async getProjectStatus() {
        this.projectStatus = await this._projectService.getAllProjectStatus();
    }
 
    async submitForm(projectDetailsForm: any) {
        debugger;
        var project = {
            "projectDetailsId": this.projectDetails.length > 0 ? this.projectDetailsId : 0,
            "projectStatus": this.managerStatus,
            "projectId": this.projectId,
            "projectDesc": this.projectDescription,
            "scopeStatus": this.scopeStatus,
            "scopeDesc": this.scopeDesc,
            "scheduleStatus": this.scheduleStatus,
            "scheduleDesc": this.scheduleDesc,
            "qualityStatus": this.qualityStatus,
            "qualityDesc": this.qualityDesc,
            "budgetStatus": this.budgetStatus,
            "budgetDesc": this.budgetDesc,
            "resourceStatus": this.resourceStatus,
            "resourceDesc": this.resourceDesc,
            "modifiedBy": this.currentUser.userId,
            "createdBy": this.currentUser.userId
        };
        

        const severityMapping: { [key: string]: string } = {
            '1': 'Low',
            '2': 'Medium',
            '3': 'High',
        };

        const probabilityMapping: { [key: string]: string } = {
            '1': 'Low',
            '2': 'Medium',
            '3': 'High',
        };
        const updatedData = this.projects.map(item => {
            if (item.severity && severityMapping[item.severity]) {
                item.severity = severityMapping[item.severity];
            }

            if (item.probability && probabilityMapping[item.probability]) {
                item.probability = probabilityMapping[item.probability];
            }

            return item;
        });
        

        const containsEmptyValues = updatedData.some(item => {
        return (
                item.risk === '' ||
                item.risk === null ||
                item.severity === '' ||
                item.severity === null ||
                item.probability === '' ||
                item.probability === null ||
                item.mitigation === '' ||
                item.mitigation === null
            );
        });

        const containEmptyProjectDetails = this.hasNullOrUndefinedOrEmptyValues(project);

        if (containEmptyProjectDetails || containsEmptyValues) {
            this.messageService.add({key: 'bc', severity:'error', summary: 'Invalid Input', detail: 'Please check Input Fields before Submitting!'});

        }
        else {
            
            await updatedData.forEach((projectDetailObj) => {
                this._projectService.createOrUpdateProjectDetailsRisks(projectDetailObj).subscribe((res) => {
                    this.projects = res;
                    
                })
            })
            await this._projectService.addProjectDetails(project).subscribe((res) => {
                this.getProjectDetails();
                this.getProjectDescription();
                this.getAllProject();
            });
            this.messageService.add({key: 'bc', severity:'success', summary: 'Success', detail: 'Update Successful for Project Details'});

            // this.router.navigate(['/projectdetails/' + this.projectId])

            
        }
    }
 
    async getAllProject() {
        debugger;
        await this._projectService.getProjectById(this.projectId).subscribe((res) => {
            this.currentProject = res;
            
        })
    }

 
    addRow() {
        
        const newDetails: projectDetailsRisk = {
            projectDetailsRiskId: 0,
            projectId: this.projectId,
            risk: "",
            riskstatus: false,
            severity: "",
            probability: "",
            mitigation: "",
            createdBy: this.currentUser.userId,
            modifiedBy: this.currentUser.userId
        };
        this.projects.push(newDetails);
    }
 
 
    removeRow() {
        if (this.projects.length > 0) {
            this.projects.pop();
        } else {
        }
    }

    async changeStatus(projectDetailsRiskId){
        debugger;
        console.log(projectDetailsRiskId);
        this._projectService.updateRiskStatus(projectDetailsRiskId).subscribe((res) => {
            this.status = res;
            this.getProjectDetails();
                this.getProjectDescription();
                this.getAllProject();
        })
    }

    getStatusColor(status: number): string {
        switch (status) {
            case 1:
                return 'green';
            case 2:
                return 'amber';
            case 3:
                return 'red';
            default:
                return ''; 
        }
    }

    getStatusText(status: number): string {
        switch (status) {
            case 1:
                return 'Green';
            case 2:
                return 'Amber';
            case 3:
                return 'Red';
            default:
                return '';
        }
    }

    hasNullOrUndefinedOrEmptyValues(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value === null || value === undefined || value === '') {
                return true; 
            }
            }
        }
        return false;
    }

    getMonday() {
        const today = new Date();
        const day = today.getDay();
        const diff = today.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(today.setDate(diff));
    }

    getSunday() {
        const monday = this.getMonday();
        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);
        return sunday;
    }

    formatDate(date) {
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    // Milestone functions

    getMilestone() {
        this._projectService.getMilestones(this.projectId).subscribe((res: any[]) => {
            // this.milestoneItems = res.map(item => {
            //     const { milestoneName, ...rest } = item;
            //     return { label: milestoneName, ...rest };
            // });

            this.milestoneItems = res;
            
        })
    }

    addMilestone() {
        this.ref = this.dialogService.open(ProjectMilestoneComponent, {
            data:{
                projectId: this.projectId,
            },
            header: 'Add Project Milestone',
            width: '40%',
            contentStyle: { overflow: 'auto' },
            baseZIndex: 10000
        });
    }

    getHeaderTemplate(milestone: any): string {
        return `${milestone.milestoneName} <i class="${this.getIconName(milestone.milestoneStatus)}"></i>`;
    }

    getIconName(status: number): string {
    switch (status) {
        case 1:
        return 'pi pi-check';
        case 2:
        return 'pi pi-exclamation-triangle';
        default:
        return 'pi pi-info-circle';
    }
    }

    onRowEditSave(product: any) {
        if (product.milestoneName == '' || product.milestoneDesc == '' || product.milestoneStatus == null || product.milestoneDate == undefined) {
            this.messageService.add({ key: 'pm', severity: 'error', summary: 'Invalid Input', detail: 'Check fields before submitting!' });
        }
        else {
            const updatedProjectDate = new Date(this.projectDate);
            updatedProjectDate.setDate(updatedProjectDate.getDate() + 1);   

            var milestone = {
                "milestoneId": product.milestoneId,
                "projectId": this.projectId,
                "milestoneName": product.milestoneName,
                "milestoneDesc": product.milestoneDesc,
                "milestoneDate": updatedProjectDate,
                "milestoneStatus": product.milestoneStatus.code == undefined ? product.milestoneStatus : product.milestoneStatus.code
            }
            
            this._projectService.createMilestones(milestone).subscribe((res) => {
                // this.milestoneItems = res;
                this.getMilestone()
                this.messageService.add({key: 'pm', severity:'success', summary: 'Success', detail: res.message});
            });
        }
    }

    onRowEditInit(project: any) {
        const inputDateString = project.milestoneDate;
        const inputDate = new Date(inputDateString);

        const options: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short',
        timeZone: 'Asia/Kolkata',
        };

        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(inputDate);

        const convertedDate = new Date(formattedDate);
        this.projectDate = convertedDate;
        
    }

    isMilestoneDateExpired(project: any): boolean {
        const milestoneDate = new Date(project.milestoneDate);
        const currentDate = new Date();
        return milestoneDate < currentDate && project.milestoneStatus != 3;
    }

    // exportExcel() {
    //     const combinedArray = this.projects.concat(...this.milestoneItems).concat(...this.projectDetails)
        
    //     import('xlsx').then((xlsx) => {
    //         const worksheet = xlsx.utils.json_to_sheet(combinedArray);
    //         const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    //         const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    //         this.saveAsExcelFile(excelBuffer, 'project_details');
    //     });
    // }

    // saveAsExcelFile(buffer: any, fileName: string): void {
    //     let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //     let EXCEL_EXTENSION = '.xlsx';
    //     const data: Blob = new Blob([buffer], {
    //         type: EXCEL_TYPE
    //     });
    //     FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    // }

}
