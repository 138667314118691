import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageServiceComponent } from 'src/services/message.service';
import { ProjectDescriptionService } from 'src/services/project-description.service';

@Component({
    templateUrl: './review-comment.component.html',
    providers: [DynamicDialogRef]
})
export class ProjectReviewCommentComponent implements OnInit {
    private projectId: number = 0;
    user: any;
    comments: [] = [];
    project: any;
    isNoPreviousReview: boolean;
    commentDesc: string = '';
    constructor(private route: ActivatedRoute, private messageServiceComponent: MessageServiceComponent, private _projectDescriptionService: ProjectDescriptionService, private messageService: MessageService) { }
    
    ngOnInit(): void {
        let fromProjectListId: number;
        this.messageServiceComponent.getProjectId().subscribe((res) => {
            fromProjectListId = res;
        })
        this.projectId = Number.isNaN(parseInt(this.route.snapshot.paramMap.get('projectId'))) ? fromProjectListId : parseInt(this.route.snapshot.paramMap.get('projectId'));
        this.messageServiceComponent.getUser().subscribe((res) => {
            this.user = res;
            
        })
        this.getAllComments();
        this.getProjectById();
    }

    async getAllComments() {
        this._projectDescriptionService.getAllComments(this.projectId).subscribe((res) => {
            this.comments = res;
            if (this.comments == null) {
                this.comments = []
            }
            
        })
    }

    async getProjectById() {
        this._projectDescriptionService.getProjectById(this.projectId).subscribe((res) => {
            this.project = res;
            
        })  
    }

    createComment() {
        if (this.commentDesc.trim() == null || this.commentDesc.trim() == '') {
            this.messageService.add({key: 'rc', severity:'error', summary: 'Invalid Input', detail: 'Check Fields before Submitting!'});
        }
        else {
            const comment = {
                commentId: 0,
                commentDesc: this.commentDesc,
                projectId: this.projectId
            }
            this._projectDescriptionService.createComment(comment).subscribe((res) => {
                this.getAllComments();
                this.commentDesc = ''
                this.messageService.add({key: 'rc', severity:'success', summary: 'Success', detail: 'Comment Posted!'});
            })
        }
    }
}