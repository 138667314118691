import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjectDetailsService } from 'src/services/project-details.service';
import { ProjectDetailsComponent } from '../project-details.component';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: './project-milestone.component.html',
    providers: [DynamicDialogRef, DatePipe]
})
export class ProjectMilestoneComponent implements OnInit {
    private projectId: number;
    private milestoneName: string = '';
    private milestoneDesc: string = '';
    private milestoneStatus: any = "Open";
    private milestoneDate = '';

    private allMilestoneStatus: any;


    constructor(private route: ActivatedRoute, private messageService: MessageService, private _projectDetailsService: ProjectDetailsService, private projectDetails: ProjectDetailsComponent, private datePipe: DatePipe){}

    ngOnInit(): void {
        this.projectId = parseInt(this.route.snapshot.paramMap.get('projectId'));

        this.allMilestoneStatus = [
            {name: 'Open', code: 1},
            {name: 'In Progress', code: 2},
            {name: 'Completed', code: 3},
        ];
    }

    onSubmit() {
        if (this.milestoneName == '' || this.milestoneDesc == '' || this.milestoneDate == '') {
            this.messageService.add({key: 'pm', severity:'error', summary: 'Invalid Input', detail: 'Check fields before submitting!'});
        }
        else {
            const angularDate = new Date(this.milestoneDate);
            const numberOfDaysToAdd = 1;
            angularDate.setDate(angularDate.getDate() + numberOfDaysToAdd);
            const formattedDate = angularDate.toISOString().split('T')[0];
            var milestone = {
                "milestoneId": 0,
                "projectId": this.projectId,
                "milestoneName": this.milestoneName,
                "milestoneDesc": this.milestoneDesc,
                "milestoneDate": formattedDate,
                "milestoneStatus": 1
            }
            
            this._projectDetailsService.createMilestones(milestone).subscribe((res) => {
                this.projectDetails.getMilestone()
                this.milestoneName = '';
                this.milestoneDesc = '';
                this.milestoneStatus = "Open";
                this.milestoneDate = '';
                this.messageService.add({key: 'pm', severity:'success', summary: 'Success', detail: res.message});
            });
            
        }
        
    }
}