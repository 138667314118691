import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { UserService } from 'src/services/user.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    providers: [DialogService]
})
export class UsersComponent implements OnInit {
    // private state variables
    private users: any = []
    isVisibleAddUserDialog = false;
    userForm: FormGroup = new FormGroup({
        userName: new FormControl(''),
        userEmailId: new FormControl(''),
        userIsAdmin: new FormControl(false)
    });
    isAdminDropdown = [{label: 'Yes', code: true}, {label: 'No', code: false}]
    
    constructor(private breadcrumbService: AppBreadcrumbService, public dialogService: DialogService, private _userService: UserService, private messageService: MessageService) {
        this.breadcrumbService.setItems([
            { label: 'Users', routerLink: ['/users'] }
        ]);
    }

    ngOnInit(): void {
        this.getAllUsers();
    }

    toggleAddUserDialog() {
        this.isVisibleAddUserDialog = !this.isVisibleAddUserDialog;
    }

    async getAllUsers() {
        this._userService.getAllUsers().subscribe((res) => {
            this.users = res;
        })
    }

    async addUser(userData: any) {
        let user = {
            "userID": 0,
            "name": userData.userName,
            "eMailId": userData.userEmailId,
            "isAdmin": userData.userIsAdmin?.code == undefined ? false : userData.userIsAdmin?.code
        }
        
        if (this.userForm.valid) {          
            this._userService.createOrUpdateUser(user).subscribe((res) => {
                this.getAllUsers();
                this.messageService.add({ key: 'uc', severity: 'success', summary: 'Success', detail: 'User Added!' });
                this.isVisibleAddUserDialog = false;
                this.userForm.reset()
            })
        }
        else {
            this.messageService.add({key: 'uc', severity:'error', summary: 'Invalid Input', detail: 'Check Fields before Submitting!'});
        }
        
    }

    onRowEditSave(userData: any) {
        
        let user = {
            "userID": userData.userID,
            "name": userData.name,
            "eMailId": userData.eMailId,
            "isAdmin": userData.isAdmin.code == undefined ? userData.isAdmin : userData.isAdmin.code
        }
        
        if (user.userID != '' || user.name != '' || user.eMailId != '') {          
            this._userService.createOrUpdateUser(user).subscribe((res) => {
                this.getAllUsers();
                this.messageService.add({ key: 'uc', severity: 'success', summary: 'Success', detail: 'User Update Successful!' });
            })
        }
        else {
            this.messageService.add({key: 'uc', severity:'error', summary: 'Invalid Input', detail: 'Check Fields before Submitting!'});
        }
    }

    clear(table: Table) {
        table.clear();
    }
}