import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute } from '@angular/router';
import { ProjectDescriptionService } from 'src/services/project-description.service';

@Component({
    selector:'project-details-history',
    templateUrl: './projectdetailshistory.component.html',
    styleUrls: ['../project-description.component.scss'],
    providers: [DynamicDialogRef]
})
export class ProjectDescriptionHistoryComponent implements OnInit {
    // private projectId: number;
    private projects: any[] = [];
    private currentProjectDetailsHistory: any[]=[];

    private weekFlag: number = 1;

    private isPrevAvailable: boolean = true;
    private isNextAvailable: boolean = false;

    isDateFetched: boolean = false;

    public formattedDate: string;
    private toDate: Date;
    private fromDate: Date;
    private project: any;
    @Input() public projectId: number;
    constructor(
        public ref: DynamicDialogRef,
        private cd: ChangeDetectorRef,
        private httpService: ProjectDescriptionService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.fromDate = new Date();
        // this.projectId = parseInt(this.route.snapshot.paramMap.get('projectId'));
        this.getDateRange(0, 'getPrev');
        // Load data for projects
        this.loadProjectsData();

        // Load data for currentProjectDetailsHistory
        this.getProjectDetailsHistory();
        this.getProject();
    }

    loadProjectsData(): void {
        this.projects=[];
        this.httpService.getProjectRiskHistory(this.fromDate, this.toDate, this.projectId).subscribe((res:any[]) => {
            if(res!=null)
            {
                this.projects = res;
                
            }            
        });        
    }

    getDateRange(week:number=0, isGetNextEnabled: string) {
        const today = this.fromDate;
        const currentDay = today.getDay();
        const daysToMonday = (currentDay + 6) % 7; 
        const daysAgo = this.isDateFetched == false ? daysToMonday + 7 * 2 : daysToMonday + 7 * 1; 
        this.isDateFetched = true

        const mondayDate = new Date(today);
        if (isGetNextEnabled != 'getNext') {
            mondayDate.setDate(today.getDate() - daysAgo);
        }
        else {
            mondayDate.setDate(today.getDate() + daysAgo)
        }

        const sundayDate = new Date(mondayDate);
        sundayDate.setDate(mondayDate.getDate() + 6);

        // this.fromDate = week == 0 ? new Date() : this.fromDate;
        
        // this.toDate = week == 0 ? new Date() : this.toDate;
        
        // const diff = ((this.fromDate.getDate() - this.fromDate.getDay()) +1) + week;
        
        // this.fromDate = new Date(this.fromDate.setDate(diff));
        // this.toDate = new Date(this.toDate.setDate(diff + 6));
        this.fromDate = mondayDate;
        this.toDate = sundayDate;
        
    }

    getProjectDetailsHistory(): void {
        this.currentProjectDetailsHistory = [];
        this.httpService.getProjectDetailHistory(this.fromDate, this.toDate, this.projectId).subscribe((res:any[]) => {
            if(res!=null)
            {
                this.currentProjectDetailsHistory.push(res);
            }            
        });
    }

    GetPrevious(){
        this.weekFlag = this.weekFlag + 1;
        this.getDateRange(-7, 'getPrev');
        this.loadProjectsData();
        this.getProjectDetailsHistory();
    }

    GetNext(){
        this.weekFlag = this.weekFlag -1;
        this.getDateRange(7, 'getNext');
        this.loadProjectsData();
        this.getProjectDetailsHistory();
    }

    getProject() {
        this.httpService.getProjectById(this.projectId).subscribe((res) => {
            this.project = res;
        })
    }
}